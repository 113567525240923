import React, { useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid } from '@mui/material';
import { useTranslation } from "react-i18next";
import { UsedVehicleImage } from '../../../api/graphql/generated/schema';
import { setLoadingStatus } from "../../../redux/loading";
import { useDispatch } from "react-redux";
import { ApolloError, ColorImage } from "../../../components";
import { openErrorSnackbar, openSuccessSnackbar } from "../../../redux/snackbar";
import { getBusinessErrorMessages, getGQLErrorMessages } from "../../../utils/graphGL/graphQLHelper";
import { Add, ExpandMore } from '@mui/icons-material';
import { DropzoneDialogBase, FileObject } from 'react-mui-dropzone';
import {
    DeleteUsedVehicleImageMutationOptions,
    UploadUsedVehicleImageMutationOptions,
    useDeleteUsedVehicleImageMutation,
    useUploadUsedVehicleImageMutation,
} from '../../../api/graphql/generated/hooks';
import { GraphQLError } from 'graphql/error/GraphQLError';

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export default function UsedCarPhotos(props: {
    images: Array<UsedVehicleImage>,
    handleUpdate: (updatedList: Array<UsedVehicleImage>) => void,
    initiallyExpanded?: boolean
}) {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [accordionExpanded, setAccordionExpanded] = React.useState<boolean | undefined>(props.initiallyExpanded)
    const [openDropZoneDialog, setOpenDropZoneDialog] = React.useState<boolean>(false);
    const [fileObjects, setFileObjects] = React.useState<FileObject[]>([]);
    const [loadingImages, setLoadingImages] = React.useState<boolean>(false);
    const [uploadImageMutation, {loading: loadingUploadImageMutation}] = useUploadUsedVehicleImageMutation();
    const [deleteImageMutation, {loading: loadingDeleteImageMutation}] = useDeleteUsedVehicleImageMutation();

    useEffect(() => {
        dispatch(setLoadingStatus(loadingUploadImageMutation || loadingDeleteImageMutation || loadingImages ));
    }, [dispatch, loadingUploadImageMutation, loadingDeleteImageMutation, loadingImages]);


    useEffect(() => {
        if(!loadingImages) {
            setFileObjects([]);
        }
    }, [loadingImages]);

    const handleUploadImage = async (base64: string, order: number) => {
        let inputOptions: UploadUsedVehicleImageMutationOptions = {
            variables: {
                input: {
                    content: base64
                }
            },
            onError: (error) => <ApolloError error={[error]}/>
        };
        try {
            const response = await uploadImageMutation(inputOptions);
            if (response?.data?.uploadUsedVehicleImage?.imageId) {
                dispatch(openSuccessSnackbar(t("userMessages.vehicleCard.photos.imageUploaded")));
                return {
                    id: response.data.uploadUsedVehicleImage.imageId,
                    fileName: response.data.uploadUsedVehicleImage.fileName,
                    order,
                    mainImage: false,
                    publicUrl: response.data.uploadUsedVehicleImage.publicUrl
                };
            } else if (response?.data?.uploadUsedVehicleImage?.errors) {
                response.data.uploadUsedVehicleImage.errors.forEach(error => dispatch(openErrorSnackbar(getBusinessErrorMessages(error, t, "vehicleCard"))));
            } else if (response.errors) {
                response.errors.forEach(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
            }
        } catch (error) {
            dispatch(openErrorSnackbar(getGQLErrorMessages(error as GraphQLError)))

        }

    }

    const handleRemoveImage = (imageId: string, fileName: string) => {
        let inputOptions: DeleteUsedVehicleImageMutationOptions = {
            variables: {
                input: {
                    imageId: imageId,
                    fileName: fileName
                }
            },
            onError: (error) => <ApolloError error={[error]}/>
        };
        deleteImageMutation(inputOptions)
            .then(response => {
                if (response?.data?.deleteUsedVehicleImage?.success) {
                    dispatch(openSuccessSnackbar(t("userMessages.vehicleCard.photos.imageDeleted")));
                    props.handleUpdate(props.images.filter((img) => img.id !== imageId));
                } else if (response?.data?.deleteUsedVehicleImage?.errors) {
                    response.data.deleteUsedVehicleImage.errors.forEach(error => dispatch(openErrorSnackbar( getBusinessErrorMessages(error, t, "vehicleCard"))));
                } else if(response.errors){
                    response.errors.forEach(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
                }
            })
            .catch(error => {
                dispatch(openErrorSnackbar(getGQLErrorMessages(error)))
            });
    }


    const handleSaveImage = async (file: FileObject, order: number): Promise<UsedVehicleImage | undefined> => {
        const b64 = await toBase64(file.file);
        return await handleUploadImage(b64 as string, order);
    }

    const handleOnSaveImages = async () => {
        setFileObjects([]);
        setOpenDropZoneDialog(false);
        setLoadingImages(true);

        const images = props.images.map((item) => item);

        const newImages: Array<UsedVehicleImage | undefined> = await Promise.all(fileObjects.map((fileObject, index) => {
            return handleSaveImage(fileObject, index);
        }));

        // @ts-ignore - undefined images are filtered out
        props.handleUpdate(images.concat(newImages.filter(item => item != null)));
    }

    const handleChangeMainImage = (imgId: string) => {
        const images = props.images.map((item) => {
            return {
                id: item.id,
                fileName: item.fileName,
                publicUrl: item.publicUrl,
                mainImage: item.mainImage,
                order: item.order
            }
        });
        const imgIndex = images?.findIndex((img) => img.id === imgId );
        images[imgIndex].mainImage = !images[imgIndex].mainImage;
        props.handleUpdate(images);
    }

    return (
        <Accordion className="card-section" variant="outlined" expanded={accordionExpanded}
                   onChange={(event, expanded) => setAccordionExpanded(expanded)}>
            <AccordionSummary
                sx={{ flexDirection: 'row-reverse' }}
                expandIcon={<ExpandMore color='secondary' />}
            >
                <h2>{t("pages.vehicleCards.detail.photos.title")}</h2>
            </AccordionSummary>
            <AccordionDetails>
                <Box mb={2}>
                    <Grid container direction={"row"} justifyContent={"flex-end"}>
                        <Button variant="outlined" onClick={() => setOpenDropZoneDialog(true)}>
                            <Add/>{t("actions.add")}
                        </Button>
                    </Grid>
                </Box>
                <Box mb={2}>
                    <Grid container direction={"row"} justifyContent={"space-evenly"} spacing={1}>
                        {props.images?.map(img => (
                            <Grid item>
                                <ColorImage
                                    imageId={img.id}
                                    imageUrl={img.publicUrl}
                                    isMainImage={img?.mainImage || false}
                                    handleUpdate={() => handleChangeMainImage(img.id)}
                                    handleRemove={() => handleRemoveImage(img.id, img.fileName)}/>
                            </Grid>
                        ))}
                    </Grid>
                    <DropzoneDialogBase
                        dialogTitle={t("pages.vehicleCards.detail.photos.dropzone.title")}
                        acceptedFiles={['image/*']}
                        fileObjects={fileObjects}
                        cancelButtonText={t("actions.discard")}
                        submitButtonText={t("actions.save")}
                        maxFileSize={5000000}
                        filesLimit={10}
                        dropzoneText={t("pages.vehicleCards.detail.photos.dropzone.tooltip")}
                        open={openDropZoneDialog}
                        onAdd={newFileObjs => {
                            setFileObjects([...fileObjects, ...newFileObjs]);
                        }}
                        onDelete={(deleteFileObj, index) => {
                            setFileObjects(fileObjects.filter(fo => fo.file.name !== deleteFileObj.file.name));
                        }}
                        onClose={() => {
                            setFileObjects([]);
                            setOpenDropZoneDialog(false);
                        }}
                        onSave={handleOnSaveImages}
                        showPreviews={true}
                        showFileNamesInPreview={true}
                    />
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}
